
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./Fonts/Poppins/Poppins-Regular.ttf') format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Poppins Medium Italic';
  src: local('Poppins Medium Italic'), url('./Fonts/Poppins/Poppins-MediumItalic.ttf') format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Poppins Medium';
  src: local('Poppins Medium'), url('./Fonts/Poppins/Poppins-Medium.ttf') format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Poppins Light';
  src: local('Poppins Light'), url('./Fonts/Poppins/Poppins-Light.ttf') format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Poppins Extra Light';
  src: local('Poppins Extra Light'), url('./Fonts/Poppins/Poppins-ExtraLight.ttf') format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Poppins Light Italic';
  src: local('Poppins Light Italic'), url('./Fonts/Poppins/Poppins-LightItalic.ttf') format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Poppins Thin';
  src: local('Poppins Thin'), url('./Fonts/Poppins/Poppins-Thin.ttf') format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Poppins Bold Italic';
  src: local('Poppins Bold Italic'), url('./Fonts/Poppins/Poppins-BoldItalic.ttf') format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Poppins Bold';
  src: local('Poppins Bold'), url('./Fonts/Poppins/Poppins-Bold.ttf') format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Poppins Semi Bold';
  src: local('Poppins Semi Bold'), url('./Fonts/Poppins/Poppins-SemiBold.ttf') format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
